body {
  background: #ffffff;
  color: #000000;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.5;
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

main {
  text-align: left;
  max-width: 32rem;
}

h1 {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0 0 2rem 0;
}

p {
  font-size: 1.25rem;
  margin: 0 0 2rem 0;
}

.projects {
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 0.5;
  font-size: 1rem;
}

.projects li {
  margin-bottom: 0.75rem;
}

.projects li:last-child {
  margin-bottom: 0;
}

.projects li:before {
  content: "—";
  margin-right: 0.5rem;
}

.projects a {
  color: inherit;
  text-decoration: none;
}

.projects a:hover {
  text-decoration: underline;
}
